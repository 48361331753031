@use "breakpoints";
@use "colors";
@use "sass:map";

@mixin less_than_tablet {
  @media (max-width: (breakpoints.$md - 1)) {
    @content;
  }
}

@mixin less_than_desktop_inclusive {
  @media (max-width: (breakpoints.$md_l - 1)) {
    @content;
  }
}

@mixin more_than_desktop_inclusive {
  @media (min-width: (breakpoints.$md_l - 1)) {
    @content;
  }
}

@mixin from_tablet {
  @media (min-width: breakpoints.$md) {
    @content;
  }
}

@mixin from_tablet_to_desktop_inclusive {
  @media (min-width: breakpoints.$md) and (max-width: (breakpoints.$md_l - 1)) {
    @content;
  }
}

@mixin from_mobile_to_tablet_inclusive {
  @media (min-width: breakpoints.$sm_s) and (max-width: (breakpoints.$md_l - 1)) {
    @content;
  }
}

@mixin tablet_only {
  @media (min-width: breakpoints.$md_ms) and (max-width: (breakpoints.$md_l - 1)) {
    @content;
  }
}

@mixin over_md_ms {
  @media (min-width: breakpoints.$md_ms) {
    @content;
  }
}

@mixin under_md_ms {
  @media (max-width: breakpoints.$md_ms) {
    @content;
  }
}

@mixin over_lg_exclusive {
  @media (min-width: (breakpoints.$lg_l + 1)) {
    @content;
  }
}

@mixin over_lg {
  @media (min-width: breakpoints.$lg_l) {
    @content;
  }
}

@mixin over_md_m {
  @media (min-width: breakpoints.$md_m) {
    @content;
  }
}

@mixin lg_l {
  @media (max-width: breakpoints.$lg_l) {
    @content;
  }
}

@mixin over_md_l {
  @media (min-width: breakpoints.$md_l) {
    @content;
  }
}

@mixin over_lg_s {
  @media (min-width: breakpoints.$lg_s) {
    @content;
  }
}

@mixin lg {
  @media (max-width: breakpoints.$lg) {
    @content;
  }
}

@mixin lg_m {
  @media (max-width: breakpoints.$lg_m) {
    @content;
  }
}

@mixin less_than_lg {
  @media (max-width: (breakpoints.$lg - 1)) {
    @content;
  }
}

@mixin lg_s {
  @media (max-width: breakpoints.$lg_s) {
    @content;
  }
}

@mixin less_than_lg_s {
  @media (max-width: (breakpoints.$lg_s - 1)) {
    @content;
  }
}

@mixin md_l {
  @media (max-width: (breakpoints.$md_l - 1)) {
    @content;
  }
}

@mixin include_md_l {
  @media (max-width: (breakpoints.$md_l)) {
    @content;
  }
}

@mixin md_m {
  @media (max-width: breakpoints.$md_m) {
    @content;
  }
}

@mixin md {
  @media (max-width: breakpoints.$md) {
    @content;
  }
}

@mixin md_landscape {
  @media screen and (max-width: breakpoints.$md) and (orientation: landscape) {
    @content;
  }
}

@mixin md_s {
  @media (max-width: breakpoints.$md_s) {
    @content;
  }
}

@mixin larger_than_mobile {
  @media (min-width: (breakpoints.$sm_xl + 1)) {
    @content;
  }
}

@mixin sm_xl {
  @media (max-width: breakpoints.$sm_xl) {
    @content;
  }
}

@mixin sm_l {
  @media (max-width: breakpoints.$sm_l) {
    @content;
  }
}

@mixin sm_m {
  @media (max-width: breakpoints.$sm_m) {
    @content;
  }
}

@mixin sm {
  @media (max-width: breakpoints.$sm) {
    @content;
  }
}

@mixin sm_s {
  @media (max-width: breakpoints.$sm_s) {
    @content;
  }
}

@mixin xsm_h {
  @media (max-height: breakpoints.$xsm_h) {
    @content;
  }
}

@mixin banner_theme($color) {
  background: map.get(colors.$banner_theme_backgrounds, $color);
  color: map.get(colors.$banner_theme_foregrounds, $color);
}
